<template>
  <div class="page-wrap">
    <div class="top-changes">
      <div class="l-where">
        系统设置<span>/</span>印章管理
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="top-change">
      <div class="color-word">协议文件上传</div>
      <div class="form-del">
        <div class="label-name">用户服务协议</div>
        <div style="display: flex;align-items: center">
          <Upload
              :show-upload-list="false"
              :headers='headers'
              :action="imageAction"
              :format="['jpg','jpeg','png','doc','docx','pdf']"
              :on-format-error="handleFormatError"
              :on-success="uploadImgSuccess"
              :on-progress="handleProgress">
            <Button type="primary" ghost>{{showOne ? '上传成功' : '选择服务协议'}}</Button>
          </Upload>
          <div class="see-del" v-if="showOne" @click="toSee('1')">服务协议详情</div>
        </div>
      </div>
      <div class="form-del">
        <div class="label-name">隐私协议</div>
        <div style="display: flex;align-items: center">
          <Upload
              :show-upload-list="false"
              :headers='headers'
              :action="imageAction"
              :format="['jpg','jpeg','png','doc','docx','pdf']"
              :on-format-error="handleFormatErrorElse"
              :on-success="uploadImgSuccessElse"
              :on-progress="handleProgressElse">
            <Button type="success" ghost>{{showTwo ? '上传成功' : '选择隐私协议'}}</Button>
          </Upload>
          <div class="see-del" v-if="showTwo" @click="toSee('2')">隐私协议详情</div>
        </div>
      </div>
      <Button type="primary" style="margin-top: 40px" @click="sendMsg">提交</Button>
    </div>
  </div>
</template>

<script>
import {addTreaty,getTreaty} from '../../../api/interFace'
import {getCookies} from '@/utils/helper'
import config from '@/config/index'
export default {
  name: "index",
  components: {
  },
  data(){
    return{
      imageAction : config.apiUrl + '/adminapi/common/uploads',
      headers:{'Authori-zation' : getCookies('token')},
      showOne:false,
      showTwo:false,
      formItem: [
        {
          type:0,
          content:''
        },
        {
          type:1,
          content:''
        }
      ],
      seeSecond:'',
      seeFirst:'',
    }
  },
  created(){
    this.getContent()
  },
  methods:{
    toSee(e){
      if(e === '1'){
        window.open(this.seeFirst)
      }else {
        window.open(this.seeSecond)
      }
    },
    goBack(){
      this.$emit('goBack')
    },
    //文件上传类型错误
    handleFormatError () {
      this.$Message.warning('暂不支持上传此格式');
    },
    //添加图片上传图片成功
    uploadImgSuccess (e) {
      this.formItem[0].content =e.data.relative_path
      this.seeFirst = e.data.src
      if(e.data.relative_path){
        this.showOne = true
      }
      this.$Message.destroy()
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress(){
      const msg = this.$Message.loading({
        content: '上传中...',
        duration: 0
      });
      setTimeout(msg, 3000);
    },
    //文件上传类型错误
    handleFormatErrorElse () {
      this.$Message.warning('暂不支持上传此格式');
    },
    //添加图片上传图片成功
    uploadImgSuccessElse (e) {
      this.formItem[1].content =e.data.relative_path
      this.seeSecond = e.data.src
      if(e.data.relative_path){
        this.showTwo = true
      }
      this.$Message.destroy()
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgressElse (){
      const msg = this.$Message.loading({
        content: '上传中...',
        duration: 0
      });
      setTimeout(msg, 3000);
    },
    getContent() {
      getTreaty().then(res=>{
        res.data.forEach(item=>{
          if(item.type == 0){
            this.seeFirst = item.content
            this.showOne = true
          }
          if(item.type == 1){
            this.seeSecond = item.content
            this.showTwo = true
          }
        })
      })
    },
    sendMsg(){
      addTreaty({content:this.formItem}).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.top-changes {
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  box-sizing: border-box;
  height: 60px;
  .l-where {
    //position: absolute;
    //left: 30px;
    //top: 50%;
    //transform: translateY(-50%);
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #1B2742;
    span {
      color: #4877E8;
    }
  }
  .r-back {
    .iconfont {
      //position: absolute;
      //right: 30px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 26px;
      cursor: pointer;

      &:hover {
        color: #4877E8;
      }
    }
  }
}
.top-change {
  width: 100%;
  padding: 20px 40px;
  height: 75vh;
  margin-top: 20px;
  background: white;
  margin-bottom: 20px;
}

.color-word {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}
.label-name {
  font-size: 14px;
  color: #676767;
  margin-bottom: 14px;
}
.form-del {
  margin-top: 30px;
}

.see-del {
  font-size: 15px;
  cursor: pointer;
  color: #999999;
  margin-left: 20px
}
.see-del:hover{
  color: #1A73E8;
}
</style>
