<template>
  <div class="user-page">
    <div class="top-change">
      <div class="l-where">
        系统设置<span>/</span>云存储
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="card-bg">
      <div class="input-nav flex">
        <div class="label">云服务商:</div>
        <div>
          <RadioGroup v-model="formCloud.service_type"   @on-change="changeConfig">
            <Radio label="1">阿里云</Radio>
            <Radio label="2">腾讯云</Radio>
            <Radio label="3">七牛云</Radio>
          </RadioGroup>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">空间域名Domain：：</div>
        <div class="val">
          <Input v-model="formCloud.domain"  placeholder="请输入空间域名Domain"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">accessKey：</div>
        <div class="val">
          <Input v-model="formCloud.access_key"  placeholder="请输入accessKey"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">secretKey：</div>
        <div class="val">
          <Input v-model="formCloud.secret_key"  placeholder="请输入secretKey"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">存储空间名称：</div>
        <div class="val">
          <Input v-model="formCloud.storage_title"  placeholder="请输入存储空间名称"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">所属地域：</div>
        <div class="val">
          <Input v-model="formCloud.area"  placeholder="请输入所属地域"/>
        </div>
      </div>
      <div class="s-b">
        <Button type="primary" @click="submitCloud">确定</Button>
      </div>
    </div>
  </div>
</template>

<script>
import {getcloud , savecloud} from '../../../api/interFace'
export default {
  name: "user",
  components:{
  },
  data(){
    return{
      inputVal:'',
      accessoriesList:'',
      formCloud:{
        status:true,
        real_status:0,
        service_type:'1',
        domain:"",
        access_key:"",
        secret_key:"",
        storage_title:"",
        area:""
      },
    }
  },
  created() {
    this.getCloudData()
  },
  methods:{
    goBack(){
      this.$emit('goBack')
    },
    changeConfig(v){
      this.formCloud.service_type = v
    },
    submitCloud(){
      if(this.formCloud.service_type==''){ this.$Message.warning('请选择云服务商'); return; }
      if(this.formCloud.domain==''){ this.$Message.warning('请填写空间域名'); return; }
      if(this.formCloud.access_key==''){ this.$Message.warning('请填写accessKey'); return; }
      if(this.formCloud.secret_key==''){ this.$Message.warning('请填写secretKey'); return; }
      if(this.formCloud.storage_title==''){ this.$Message.warning('请填写存储空间名称'); return; }
      if(this.formCloud.area==''){ this.$Message.warning('请填写所属地域'); return; }
      this.formCloud.status = this.formCloud.status ? 1 : 0;
      savecloud(this.formCloud).then(res=>{
        this.$Message.success(res.msg)
        this.formCloud.status_old = this.formCloud.status;
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    getCloudData(){
      getcloud().then(res=>{
        this.formCloud = {
          status:res.data.status == 1 ? true : false,
          real_status: res.data.status,
          service_type:res.data.service_type,
          domain:res.data.domain,
          access_key:res.data.access_key,
          secret_key:res.data.secret_key,
          storage_title:res.data.storage_title,
          area:res.data.area
        };
        this.formCloud.status_old = this.formCloud.status;
      }).catch({

      })
    },
  }
}
</script>

<style scoped lang="scss">
.user-page {
  .top-change {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    height: 70px;
    .l-where {
      //position: absolute;
      //left: 30px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 18px;
      font-weight: bold;
      color: #1B2742;
      span {
        color: #4877E8;
      }
    }
    .r-back {
      .iconfont {
        //position: absolute;
        //right: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 26px;
        cursor: pointer;

        &:hover {
          color: #4877E8;
        }
      }
    }
    .middle-width {
      width: 30%;
      margin: auto;
    }
    .nav-name {
      font-size: 20px;
      font-weight: bold;
      color: #545F78;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
      position:relative;
      &:hover{
        color: #4877E8;
      }
    }
    .active {
      color: #4877E8;
      &:after{
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #4877E8;
        border-radius: 1px;
      }
    }
  }
  .card-bg {
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    background: #fff;
    padding: 50px 60px;
    box-sizing: border-box;
    min-height: 450px;
    position: relative;
    .s-b {
      position: absolute;
      left: 40%;
      transform: translateX(-50%);
      bottom: 20px;
    }
    .input-nav {
      margin-bottom: 25px;
      .label {
        font-size: 14px;
        font-weight: bold;
        color: #545F78;
        text-align: right;
        width: 140px;
      }
      .long {
        width: 130px;
      }
      .val {
        width: 350px;
        .up-text {
          font-size: 14px;
          color: #545F78;
        }
        .else-up {
          display: flex;
          .upload-style {
            width: 120px;
            height: 120px;
            border-radius: 4px;
            border: 1px solid #C8CEDA;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
          }
          .pic-wrap {
            display: flex;
            flex-wrap: wrap;
            margin-left: 12px;
          }
          .img-list {
            width: 100px;
            height: 100px;
            position: relative;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 4px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 4px;
            }
            .close {
              position: absolute;
              top: -15px;
              right: -15px;
              cursor: pointer;
              color: #dd0000;
              display: none;
            }
          }
          .img-list:hover .close {
            display: inline-block;
          }
        }
      }
    }
  }
  .top-number {
    width: 100%;
    padding: 40px 60px 20px 30px;
    box-sizing: border-box;
    background: white;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    .sss {
      display: flex;
      align-items: center;
      margin-left: 30px;
      &:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 60px;
        background: #DBDEE6;
        margin-left: 20px;
      }
    }
    .wrap-item {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .num {
        font-size: 44px;
        font-weight: 400;
        color: #1B2742;
      }
      .word {
        font-size: 18px;
        font-weight: bold;
        color: #818899;
      }
      .btn-s {
        width: 80px;
        height: 30px;
        margin-top: 10px;
      }
      .btn {
        width: 80px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #4877E8;
        border-radius: 6px;
        font-size: 14px;
        color: #4877E8;
        margin-top: 10px;
      }
    }

  }
  .history {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    width: 100%;
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #1B2742;
      display: flex;
      align-items: center;
      .iconfont {
        color: #4877E8;
        margin-right: 8px;
        font-size: 20px;
      }
    }
    .card-wrap {
      width: 100%;
      height: 174px;
      background: #FFFFFF;
      border: 1px solid #E4EAF9;
      border-radius: 7px;
      margin-top: 10px;
    }

  }

}

</style>
