<template>
  <div class="user-page">
    <div class="top-change">
      <div class="l-where">
        系统设置<span>/</span>微信小程序
      </div>
      <div class="middle-width flex-b">
        <div class="nav-name" :class="activeNav === '1' ? 'active' : ''" @click="changeTable('1')">小程序配置</div>
        <div class="nav-name" :class="activeNav === '2' ? 'active' : ''" @click="changeTable('2')">支付配置</div>
        <div class="nav-name" :class="activeNav === '3' ? 'active' : ''" @click="changeTable('3')">小程序更新</div>
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="card-bg" v-if="activeNav === '1'">
      <div class="input-nav flex">
        <div class="label">小程序域名：</div>
        <div class="val">
          <Input v-model="formdata.url"  placeholder="请输入小程序域名"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">小程序名称：</div>
        <div class="val">
          <Input v-model="formdata.name"  placeholder="请输入小程序名称"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">appid：</div>
        <div class="val">
          <Input v-model="formdata.appid"  placeholder="请输入appid"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">secret：</div>
        <div class="val">
          <Input v-model="formdata.appsecret"  placeholder="请输入secret"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">小程序描述：</div>
        <div class="val">
          <Input v-model="formdata.desc" type="textarea" :rows="3" placeholder="请输入小程序描述"/>
        </div>
      </div>
      <div class="s-b" >
        <Button type="primary" @click="goone">确定</Button>
      </div>
    </div>
    <div class="card-bg" v-if="activeNav === '2'">
      <div class="input-nav flex">
        <div class="label long">微信支付商户号：</div>
        <div class="val">
          <Input v-model="paylist.mchid"  placeholder="请输入微信支付商户号"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label long">微信支付商户秘钥：</div>
        <div class="val">
          <Input v-model="paylist.key"  placeholder="请输入微信支付商户秘钥"/>
        </div>
      </div>
      <div class="s-b">
        <Button type="primary" @click="payBtn">确定</Button>
      </div>
    </div>
    <div v-if="activeNav === '3'">
      <div class="top-number">
        <div class="sss">
          <div class="wrap-item">
            <div class="num">{{ currentVersion }}</div>
            <div class="word">当前版本</div>
            <div class="btn-s"></div>
          </div>
        </div>
        <div class="sss">
          <div class="wrap-item">
            <div class="num">{{ latestVersion || '暂无' }}</div>
            <div class="word">最新版本</div>
            <div class="btn-s">
              <div class="btn flex-c" v-if="releaseApplet === 1" @click="uploadApplets">更新</div>
            </div>
          </div>
        </div>
      </div>
      <div class="history">
        <div class="title"><i class="iconfont icon-shijian"></i>历史版本记录</div>
        <div class="card-wrap" v-for="(item,index) in historyList" :key="index">
          <div>{{ item._content }}</div>
          <div>版本号:{{ item.version_code }}</div>
          <div>{{ item.created_at }}</div>
        </div>
      </div>
    </div>
    <Modal
        :title="applet_name"
        v-model="show_applets"
        :mask-closable="false" style="text-align: center" footer-hide>
      <img :src="applets_login" style="width:300px;height:300px"/>
      <div class="login-must" v-if="showMust">请尽快扫码登录</div>
    </Modal>
  </div>
</template>

<script>
import {save , updateRecord , savepay , applets , paylist , saveQrcode} from '../../../api/interFace'
import {appletsCheck ,appletsLogin} from '../../../api/appletsupload'
var that;
export default {
  name: "user",
  components:{},
  data(){
    return{
      activeNav:'1',
      inputVal:'',
      applet_name:'请扫码登录小程序',
      show_applets:false,
      showMust:false,
      applets_login:"",
      formdata:{
        url:'',
        appid:'',
        appsecret:'',
        name:'',
        desc:'',
        key:'',
        uploadBtn:false
      },
      paylist:{
        appid:'',
        appsecret:'',
        key:'',
        mchid:'',
      },
      qrcode:'',
      currentVersion:'',
      latestVersion:'',
      historyList:[],
      timer:"",
      unId:'',
      releaseApplet:''
    }
  },
  created() {
    that = this;
    this.getVersion()
    this.getlist()
    this.getpaylist()
    this.unId = this.userInfo.uniacid_id
  },
  computed: {
    userInfo(){
      return this.$store.state.userInfo
    },
  },
  destroyed() {
    clearInterval(that.timer)
  },
  methods:{
    goBack(){
      this.$emit('goBack')
    },
    uploadApplets(){
      let _this = this
      this.$Modal.confirm({
        title: '提示',
        content: '是否上传小程序',
        onOk: () => {
          this.$Message.info({
            content: '正在获取登录二维码',
            duration: 0
          });
          appletsLogin({
            url:_this.formdata.url,
            appid:_this.formdata.appid,
            name:_this.formdata.name,
            authentication:_this.formdata.authentication,
            version:_this.latestVersion,
            desc:_this.formdata.desc,
            uniacid:_this.unId,
            type:1
          }).then(res=>{
            _this.show_applets = true;
            _this.applet_name= '请扫码登录小程序'
            _this.applets_login = res.img;
            _this.$Message.destroy();
            _this.applets_id = res.id;
            that.timer =  setInterval(() => {
              appletsCheck({
                id : _this.applets_id,
                uniacid :_this.unId,
                type:1
              }).then(res=>{
                if(res.code === 200){
                  if(res.status === 3){
                    clearInterval(that.timer)
                    _this.$Message.error(res.msg)
                    _this.show_applets = false;
                    _this.$Message.destroy()
                  }else if(res.status === 2){
                    _this.applet_name= '小程序预览二维码'
                    _this.applets_login = res.data;
                    _this.$Message.destroy()
                    _this.showMust=false
                    clearInterval(that.timer)
                    saveQrcode({qrcode:res.data}).then(res=>{
                      _this.$Message.success(res.msg)
                    }).catch(res=>{
                      _this.$Message.warning(res.msg)
                    })
                  }else if(res.status === 0){
                    _this.$Message.destroy()
                    _this.showMust=true
                  } else {
                    _this.$Message.destroy()
                    _this.$Message.loading({
                      content: '正在上传...',
                      duration: 0
                    });
                  }
                }
              }).catch(err=>{
                _this.$Message.warning(err.msg)
              })
            }, 10000)
          }).catch(err=>{
            _this.$Message.destroy()
            _this.$Message.warning(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    getVersion(){
      updateRecord().then(res=>{
        this.latestVersion = res.data.latest_version
        this.currentVersion = res.data.current_version
        this.historyList = res.data.list
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    getpaylist(){
      paylist().then(res=>{
        this.paylist = res.data
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    getlist(){
      applets().then(res=>{
        this.formdata = res.data
        this.formdata.uploadBtn = this.formdata.appid != "" ? true : false;
        this.qrcode = res.data.qr_code
        this.releaseApplet = res.data.is_release_applet
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    goone(){
      var index = this.formdata.url.lastIndexOf("/");
      let val
      val = this.formdata.url.substring(index + 1, this.formdata.url.length);
      this.formdata.url = val
      if(this.formdata.url=='') return this.$Message.warning('请填写小程序url')
      if(this.formdata.appid=='') return this.$Message.warning('请填写APPID')
      if(this.formdata.name=='') return this.$Message.warning('请填小程序名称')
      if(this.formdata.appsecret=='') return this.$Message.warning('请填写APP Secret')
      if(this.formdata.desc=='') return this.$Message.warning('请填写小程序描述')
      save(this.formdata).then(res=>{
        this.$Message.success(res.msg)
        this.getlist()
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    getCaption(obj) {
      var index = obj.lastIndexOf("//");
      obj = obj.substring(index + 1, obj.length);
      return obj;
    },
    payBtn(){
      this.paylist.appid = this.formdata.appid
      this.paylist.appsecret = this.formdata.appsecret
      if(this.paylist.appid=='') return this.$Message.warning('请返回小程序配置填写APPID')
      if(this.paylist.appsecret=='') return this.$Message.warning('请返回小程序配置填写APP Secret')
      if(this.paylist.mchid=='') return this.$Message.warning('请填写微信支付商户号')
      if(this.paylist.key=='') return this.$Message.warning('请生成微信支付密钥')
      savepay(this.paylist).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })

    },
    changeTable(type){
      this.activeNav = type
    },
  }
}
</script>

<style scoped lang="scss">
.user-page {
  .top-change {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    .l-where {
      //position: absolute;
      //left: 30px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 18px;
      font-weight: bold;
      color: #1B2742;
      span {
        color: #4877E8;
      }
    }
    .r-back {
      .iconfont {
        //position: absolute;
        //right: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 26px;
        cursor: pointer;

        &:hover {
          color: #4877E8;
        }
      }
    }
    .middle-width {
      width: 30%;
      margin: auto;
    }
    .nav-name {
      font-size: 20px;
      font-weight: bold;
      color: #545F78;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
      position:relative;
      &:hover{
        color: #4877E8;
      }
    }
    .active {
      color: #4877E8;
      &:after{
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #4877E8;
        border-radius: 1px;
      }
    }
  }
  .card-bg {
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    background: #fff;
    padding: 50px 60px;
    box-sizing: border-box;
    min-height: 450px;
    position: relative;
    .s-b {
      position: absolute;
      left: 40%;
      transform: translateX(-50%);
      bottom: 20px;
    }
    .input-nav {
      margin-bottom: 25px;
      .label {
        font-size: 14px;
        font-weight: bold;
        color: #545F78;
        text-align: right;
        width: 100px;
      }
      .long {
        width: 130px;
      }
      .val {
        width: 350px;
      }
    }
  }
  .top-number {
    width: 100%;
    padding: 40px 60px 20px 30px;
    box-sizing: border-box;
    background: white;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    .sss {
      display: flex;
      align-items: center;
      margin-left: 30px;
      &:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 60px;
        background: #DBDEE6;
        margin-left: 20px;
      }
    }
    .wrap-item {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .num {
        font-size: 44px;
        font-weight: 400;
        color: #1B2742;
      }
      .word {
        font-size: 18px;
        font-weight: bold;
        color: #818899;
      }
      .btn-s {
        width: 80px;
        height: 30px;
        margin-top: 10px;
      }
      .btn {
        width: 80px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #4877E8;
        border-radius: 6px;
        font-size: 14px;
        color: #4877E8;
        margin-top: 10px;
      }
    }

  }
  .history {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    width: 100%;
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #1B2742;
      display: flex;
      align-items: center;
      .iconfont {
        color: #4877E8;
        margin-right: 8px;
        font-size: 20px;
      }
    }
    .card-wrap {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #E4EAF9;
      border-radius: 7px;
      margin-top: 10px;
      padding: 16px;
      box-sizing: border-box;
      font-size: 16px;
    }

  }

}

</style>
