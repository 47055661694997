<template>
  <div class="seal-page">
    <div class="top-change">
      <div class="l-where">
        系统设置<span>/</span>印章管理
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="seal-nav">
      <div class="seal-title">我的企业印章</div>
      <Alert type="warning" show-icon style="margin-top: 12px">选择上传印章,上传印章图片大小为160*160,图片格式为Png</Alert>
      <div class="choose-style">
        <div class="table-btn blue" @click="creatSeal">创建印章</div>
        <Upload
            :show-upload-list="false"
            :headers='headers'
            :action="imageAction"
            :on-format-error="handleFormatError"
            :on-progress="handleProgress"
            :format="['jpg','jpeg','png',]"
            :on-success="uploadImgSuccess">
          <div class="table-btn edit">
            上传印章
          </div>
        </Upload>
      </div>
      <div class="seal-group">
        <div class="seal-nav-item" v-for="(item,index) in list" :key="index">
          <div class="seal-img flex-c">
            <img :src="item.seal_path"/>
            <div class="do-it"></div>
            <div class="do-btn">
              <div class="l-icons flex-c" @click="seeImg(item.seal_path,'1')"><i class="iconfont icon-chakan2"></i></div>
              <div class="l-icons flex-c" @click="delSeal(item.id)"><i class="iconfont icon-shanchu"></i></div>
            </div>
          </div>
          <div class="out-height">
            <div class="use-btn flex-c" v-if="item.status === 0" @click="useSeal(item.id)">使用</div>
          </div>
        </div>
      </div>
    </div>
<!--    确认印章上传提示框-->
    <div class="seal-pop" v-if="showSeal">
      <div class="pop"></div>
      <div class="seal-card fixed flex-c">
        <Icon type="md-close" size="30" @click="closeImg" class="close" />
        <div class="require-seal">
          <img :src="showPic">
        </div>
        <Button type="primary" v-if="justSee" style="margin-top: 20px" @click="checkUp">确认上传</Button>
      </div>
    </div>
    <!-- 创建印章弹出层-->
    <modal v-if="showModal" :modalInfo="modalInfo" @giveVal="getVal" @closeModal="showModal=false"></modal>
  </div>
</template>

<script>
import modal from '@/components/modal/index'
import {getCookies} from '@/utils/helper'
import config from '@/config/index'
import {addImgsseal , seallist , editstamp , deleteStamp , stampaddsign} from '../../../api/interFace'
export default {
  name: "seal",
  components: {
    modal
  },
  data() {
    return {
      modalInfo: {
        title: '创建印章',
        inputList: [
          {
            label: '公司名称',
            inputType: 'text',
            inputVal: ''
          },
          {
            label: '企业编码',
            inputType: 'number',
            inputVal: ''
          },
          {
            label: '印章类型',
            inputType: 'text',
            inputVal: ''
          },
        ]
      },
      imageAction: config.apiUrl + '/adminapi/common/sigin_uploads',
      headers: {'Authori-zation': getCookies('token')},
      list:[],
      showModal: false,
      showSeal: false,
      justSee: true,
      showPic:'',
      relative_path:'',
      seal: {
        type: 1,
        str: '',
        strnumber: '',
        strname: '',
        qysseal_type: ''
      },
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    getVal(e) {
      this.seal.str = e[0]
      this.seal.strnumber = e[1]
      this.seal.qysseal_type = e[2]
      stampaddsign(this.seal).then(async res => {
        this.$Message.success(res.msg)
        this.showModal =false
        this.getlist()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    creatSeal(){
      this.showModal =true
      this.reset()
    },
    reset(){
      this.modalInfo= {
        title: '创建印章',
        inputList: [
          {
            label: '公司名称',
            inputType: 'text',
            inputVal: ''
          },
          {
            label: '企业编码',
            inputType: 'number',
            inputVal: ''
          },
          {
            label: '印章类型',
            inputType: 'text',
            inputVal: ''
          },
        ]
      }
    },
    seeImg(path){
      this.showPic = path
      this.showSeal = true
      this.justSee = false
    },
    generate(){
      if(this.$store.state.userInfo.auth_status === 0){
        if(this.seal.str=='') return this.$Message.warning('请填写公司名称')
      }
      stampaddsign(this.seal).then(async res => {
        this.$Message.success(res.msg)
        this.getlist()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    goBack(){
      this.$emit('goBack')
    },
    getlist(){
      seallist({type:'1'}).then(async res => {
        this.list = res.data;
      }).catch(res => {
        console.log(res)
        this.list = [];
      })
    },
    delSeal(id){
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此用户，删除后无法恢复！！！',
        onOk: () => {
          deleteStamp({id:id}).then(async res => {
            this.getlist()
            this.$Message.success(res.msg)
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    useSeal(id){
      editstamp({id:id}).then(async res => {
        this.getlist()
        this.$Message.success(res.msg)
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    checkUp (){
      addImgsseal({seal_path:this.relative_path,type:'1'}).then(res=>{
        console.log(res)
        this.$Message.success(res.msg);
        this.showSeal = false
        this.getlist()
      }).catch(res=>{
        this.$Message.error(res.msg);
      })
    },
    closeImg(){
      this.showPic = ''
      this.relative_path = ''
      this.showSeal = false
    },
    handleProgress(){
      const msg = this.$Message.loading({
        content: '上传中...',
        duration: 0
      });
      setTimeout(msg, 3000);
    },
    //添加图片上传图片成功
    uploadImgSuccess (e) {
      this.showPic = e.data.src
      this.relative_path = e.data.relative_path
      this.showSeal = true
      this.$Message.destroy()
      this.$Message.success(e.msg);
    },
    //文件上传类型错误
    handleFormatError () {
      this.$Message.warning('暂不支持上传此格式');
    },
  }
}
</script>

<style scoped lang="scss">
.seal-page {
  .top-change {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    height: 60px;
    .l-where {
      //position: absolute;
      //left: 30px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #1B2742;
      span {
        color: #4877E8;
      }
    }
    .r-back {
      .iconfont {
        //position: absolute;
        //right: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 26px;
        cursor: pointer;

        &:hover {
          color: #4877E8;
        }
      }
    }
  }
  .go-back {
    width: 100px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #C8CEDA;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    color: #545F78;
    cursor: pointer;
  }

  .seal-nav {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    margin-top: 20px;

    .seal-title {
      font-size: 18px;
      font-weight: bold;
      color: #545F78;
    }

    .choose-style {
      display: flex;
      align-items: center;
      margin-top: 12px;

      .blue {
        margin-right: 20px;
      }
    }

    .seal-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 30px;
      .seal-nav-item {
        margin-right: 20px;
        margin-bottom: 20px;
        .seal-img {
          width: 200px;
          height: 200px;
          background: #FFFFFF;
          border: 1px solid #C8CEDA;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          img{
            width: 140px;
            height: 140px;
            object-fit: cover;
          }
          &:hover .do-it{
            display: inline-block;
          }
          &:hover .do-btn{
            display: flex;
          }
          .do-it {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: #191A1F;
            opacity: 0.3;
            display: none;
          }
          .do-btn {
            display: flex;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            display: none;
            .l-icons {
              flex: 1;
              height: 40px;
              background: white;
              cursor: pointer;
              &:first-child{
              border-right: 1px solid #C8CEDA;
                .iconfont{
                  color: #4877E8;
                  font-size: 24px;
                }
            }
              &:last-child{
                .iconfont{
                  color: #FD5454;
                  font-size: 21px;
                }
              }
            }
          }
        }
        .out-height {
          width: 70px;
          height: 34px;
          margin-top: 10px;
        }
        .use-btn {
          width: 70px;
          height: 34px;
          background: #FFFFFF;
          border: 1px solid #C8CEDA;
          border-radius: 4px;
          font-size: 16px;
          color: #545F78;
          cursor: pointer;
          &:hover{
            border-color: #4877E8;
            color: #4877E8;
          }
        }
      }
    }
  }

  .seal-card {
    width: 360px;
    height: 360px;
    background: white;
    border-radius: 5px;
    flex-direction: column;
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    .require-seal {
      width: 220px;
      height: 220px;
      border-radius: 6px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
  }
}

</style>
