<template>
  <div>
    <div class="top-change">
      <div class="l-where">
        系统设置<span>/</span>印章审批
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="b-table" v-if="storePrams.status === '0'">
      <Table :columns="platformColumns" :data="storeList">
        <template slot-scope="{ row }" slot="operation">
          <div class="flex">
            <div class="table-btn blue" @click="agree(row)">同意</div>
            <div class="table-btn danger" @click="clearDel(row.id)">拒绝</div>
          </div>
        </template>
        <template slot-scope="{ row }" slot="account">
          <div>{{row.seal_id ? row.seal_id.seal_type_name : ''}}</div>
        </template>
        <template slot-scope="{ row }" slot="real_name">
          <div>{{row.user_id.real_name}}</div>
        </template>
        <template slot-scope="{ row }" slot="admin">
          <div>{{row.user_id.account}}</div>
        </template>
      </Table>
      <br/>
      <Page :total="storePrams.total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
    <div class="b-table" v-if="storePrams.status === '1'">
      <Table :columns="platformColumn" :data="storeList">
        <template slot-scope="{ row }" slot="account">
          <div>{{row.seal_id ? row.seal_id.seal_type_name : ''}}</div>
        </template>
        <template slot-scope="{ row }" slot="real_name">
          <div>{{row.user_id.real_name}}</div>
        </template>
        <template slot-scope="{ row }" slot="admin">
          <div>{{row.user_id.account}}</div>
        </template>
        <template slot-scope="{ row }" slot="status">
          <div>{{row.status === 0 ? '待审核' : row.status === 1 ? '审核成功' : row.status === 2 ?  '审核失败' : ''}}</div>
        </template>
      </Table>
      <br/>
      <Page :total="storePrams.total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
    <Modal
        title="拒绝申请"
        v-model="showMsg"
        @on-ok="requireMsg"
        :mask-closable="false">
      <div class="input-wrap">
        <div class="wrap-label">拒绝原因:</div>
        <div class="wrap-inp">
          <Input v-model="refuse_reason" type="textarea" :rows="4" placeholder="请输入拒绝原因" style="width: 400px" />
        </div>
      </div>
    </Modal>
    <Modal
        title="同意申请"
        v-model="showMore"
        @on-ok="requireMore"
        :mask-closable="false">
      <div style="display: flex;align-items: center;margin-top: 40px;margin-bottom: 40px">
        <div class="wrap-label">短信提醒</div>
        <div style="margin-left: 16px;">
          <i-switch size="large" v-model="is_sms" :value="is_sms" :true-value="1" :false-value="0">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {examineList,examineSeal} from '../../../api/interFace'
export default {
  name: "index",
  data(){
    return{
      platformColumns: [
        {
          title: '#',
          key: 'id',
          width: 80,
          align:'center'
        },
        {
          title: '用户姓名',
          slot: 'real_name',
          align:'center'
        },
        {
          title: '申请账户',
          slot: 'admin',
          align:'center'
        },
        {
          title: '印章名称',
          slot: 'account',
          align:'center'
        },
        {
          title: '使用次数',
          key: 'seal_num',
          align:'center'
        },
        {
          title: '申请原因',
          key: 'apply_reason',
          align:'center'
        },
        {
          title: '申请时间',
          key: 'created_at',
          align:'center'
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      platformColumn: [
        {
          title: '#',
          key: 'id',
          width: 80,
          align:'center'
        },
        {
          title: '用户姓名',
          slot: 'real_name',
          align:'center'
        },
        {
          title: '申请账户',
          slot: 'admin',
          align:'center'
        },
        {
          title: '印章名称',
          slot: 'account',
          align:'center'
        },
        {
          title: '使用次数',
          key: 'seal_num',
          align:'center'
        },
        {
          title: '申请原因',
          key: 'apply_reason',
          align:'center'
        },
        {
          title: '申请状态',
          key: 'status',
          slot: 'status',
          align:'center'
        },
        {
          title: '拒绝原因',
          key: 'refuse_reason',
          align:'center'
        },
      ],
      storeList:[],
      showMsg:false,
      showMore:false,
      storePrams: {
        limit: 10,
        page: 1,
        total: 0,
        status:'0'
      },
      refuse_reason:'',
      needId:'',
      is_sms:1
    }
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      examineList(this.storePrams).then(res=>{
        this.storeList = res.data.data || []
        this.storePrams.total = res.data.total
      })
    },
    goBack(){
      this.$emit('goBack')
    },
    checkIt(e){
      this.storePrams.status = e
      this.getList()
    },
    requireMsg(){
      examineSeal({id:this.msgID,status:'2',refuse_reason:this.refuse_reason}).then(res=>{
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    agree(e){
      this.showMore = true
      this.needId = e.id
    },
    requireMore(){
      this.$Modal.confirm({
        title: '操作提示',
        content: '请问您是否确认此用户使用该印章，确认无误后请点击确定按钮。',
        onOk: () => {
          examineSeal({id:this.needId,status:'1',is_sms:this.is_sms}).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    clearDel(id){
      this.msgID = id
      this.showMsg = true
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
  }
}
</script>

<style scoped lang="scss">
.top-change {
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  box-sizing: border-box;
  height: 60px;
.l-where {
  //position: absolute;
  //left: 30px;
  //top: 50%;
  //transform: translateY(-50%);
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1B2742;
span {
  color: #4877E8;
}
}
.r-back {
.iconfont {
  //position: absolute;
  //right: 30px;
  //top: 50%;
  //transform: translateY(-50%);
  font-size: 26px;
  cursor: pointer;

&:hover {
   color: #4877E8;
 }
}
}
}
.top-wrap {
  background: white;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 20px;
  border-radius: 4px;
  box-shadow:2px 5px 2px -2px  #e7e5e0;
}
.input-wrap {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.b-table {
  margin-top: 20px;
  background: white;
  padding: 30px;
  box-sizing: border-box;
}
.wrap-label {
  font-size: 14px;
  margin-right: 12px;
}
</style>
<style>
.top-wrap .ivu-tabs-nav .ivu-tabs-tab {
  font-size: 18px!important;
}
</style>
