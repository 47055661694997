<template>
  <div class="page-wrap">
    <div class="card-group" v-if="!useType">
      <div class="card-bg flex-c"
           v-for="(item,index) in cardList"
           :key="index"
           @click="chooseItem(item.permissions_rote)"
           :style="'background-image:url(' + item.icon +')'">
        <div class="middle-name flex-c">{{item.permissions_name}}</div>
      </div>
    </div>
    <Seal v-if="useType === 'seal-management'" @goBack="afresh"></Seal>
    <User v-if="useType === 'user-management'" @goBack="afresh"></User>
    <Applet v-if="useType === 'wx-applet'" @goBack="afresh"></Applet>
    <Payment v-if="useType === 'H5-payment'" @goBack="afresh"></Payment>
    <Oem v-if="useType === 'oem-config'" @goBack="afresh"></Oem>
    <Cloud v-if="useType === 'cloud-storage'" @goBack="afresh"></Cloud>
    <Apply-seal v-if="useType === 'seal-application'" @goBack="afresh"></Apply-seal>
    <Approve-seal v-if="useType === 'seal-examine'" @goBack="afresh"></Approve-seal>
    <Privacy v-if="useType === 'privacy-agreement'" @goBack="afresh"></Privacy>
    <Defalut v-if="useType === 'signer-management'" @goBack="afresh"></Defalut>
    <Ticket v-if="useType === 'contract-label'" @goBack="afresh"></Ticket>
  </div>
</template>

<script>
import Seal from './components/seal'
import User from './components/user'
import Applet from './components/applet'
import Payment from './components/payment'
import Oem from './components/oem'
import Cloud from './components/cloud'
import ApplySeal from './components/applySeal'
import ApproveSeal from './components/approveSeal'
import Privacy from './components/privacy'
import Defalut from "./components/defalut";
import Ticket from "./components/ticket";
export default {
  name: "index",
  components:{
    Seal,
    User,
    Applet,
    Oem,
    Cloud,
    Payment,
    ApproveSeal,
    Privacy,
    Defalut,
    Ticket,
    ApplySeal
  },
  data(){
    return{
      cardList:[],
    }
  },
  computed: {
    settingList(){
      return this.$store.state.settingMenu
    },
    useType(){
      return this.$store.state.activeType
    }
  },
  created() {
    this.cardList = this.settingList
  },
  methods:{
    chooseItem(type){
      this.$store.commit('setType', type)
    },
    afresh(){
      this.$store.commit('setType', '')
    }
  }
}
</script>

<style scoped lang="scss">
.page-wrap {
  .card-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .card-bg{
    width: 368px;
    height: 128px;
    border-radius: 14px;
    margin-right: 30px;
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    border: 2px solid #fff;
    box-sizing: border-box;
    &:hover {
      border: 2px solid #4877E8;
    }
    .middle-name {
      width: 154px;
      height: 48px;
      background: #EDEFF3;
      border-radius: 24px;
      font-size: 18px;
      color: #1B2742;
    }
  }

}

</style>
