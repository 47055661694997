<template>
  <div class="seal-page">
    <div class="top-change">
      <div class="l-where">
        系统设置<span>/</span>印章申请
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="seal-nav">
      <div class="seal-group">
        <div class="seal-nav-item" v-for="item in 1" :key="item">
          <div class="seal-img flex-c">
            <img src="../../../assets/imgs/258.jpg"/>
            <div class="do-it"></div>
            <div class="do-btn">
              <div class="l-icons flex-c"><i class="iconfont icon-chakan2"></i></div>
              <div class="l-icons flex-c"><i class="iconfont icon-shanchu"></i></div>
            </div>
          </div>
          <div class="use-btn flex-c">使用</div>
        </div>
      </div>
    </div>
    <div class="table-wrap">
      <div class="nav-title">合同管理</div>
      <div class="table-btn blue" @click="creatSeal">创建印章</div>
      <Table :columns="platformColumns" :data="storeList" style="margin-top: 20px">
        <template slot-scope="{ row }" slot="name">
          <div>{{row.seal_id.seal_content}}</div>
        </template>
        <template slot-scope="{ row }" slot="status">
          <div>{{row.status === 0 ? '待审核' : row.status === 1 ? '审核成功' : row.status === 2 ?  '审核失败' : ''}}</div>
        </template>
      </Table>
    </div>
    <div class="choose-pop" v-if="needChoose">
      <div class="pop"></div>
      <div class="modal fixed">
        <div class="top-wrap">
          <div class="wrap-label">企业印章(必选)</div>
          <div class="seal-list">
            <div class="seal-img flex-c"
                 :class="formItem.seal_id === item.id ? 'active' : ''"
                 @click="chooseImg(item)"
                 v-for="(item,index) in seal_list"
                 :key="index">
              <img :src="item.seal_path" alt="">
            </div>
          </div>
          <div class="wrap-label">使用次数</div>
          <Input v-model="formItem.seal_num" type="number" placeholder="请输入使用次数" style="width: 400px" />
          <div class="wrap-label">申请原因(必选)</div>
          <Input v-model="formItem.apply_reason" type="textarea" :rows="4" style="width: 400px" placeholder="请输入申请原因" />
          <div style="display: flex;align-items: center;margin-top: 12px">
            <div class="wrap-label-s">短信提醒</div>
            <div style="margin-left: 16px">
              <i-switch size="large" v-model="formItem.is_sms" :value="formItem.is_sms" :true-value="1" :false-value="0" @on-change="onchangeIsShow">
                <span slot="open">开启</span>
                <span slot="close">关闭</span>
              </i-switch>
            </div>
          </div>
          <div style="margin-top: 20px" class="flex-c">
            <Button type="primary" @click="creatIt">申请</Button>
            <Button style="margin-left: 20px" @click="cancelIt">取消</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {sealList,sealCreate,applyList} from '../../../api/interFace'
export default {
  name: "index",
  data(){
    return{
      platformColumns: [
        {
          title: '#',
          key: 'id',
          width: 60,
          align:'center'
        },
        {
          title: '印章名称',
          key: 'account',
          slot: 'name',
          align:'center'
        },
        {
          title: '使用次数',
          key: 'seal_num',
          align:'center'
        },
        {
          title: '申请原因',
          key: 'apply_reason',
          align:'center'
        },
        {
          title: '申请时间',
          key: 'created_at',
          align:'center'
        },
        {
          title: '申请状态',
          key: 'status',
          slot: 'status',
          align:'center'
        },
        {
          title: '审核时间',
          key: 'updated_at',
          align:'center'
        },
        {
          title: '拒绝原因',
          key: 'refuse_reason',
          align:'center'
        },
      ],
      storeList:[],
      seal_list:[],
      value6:'',
      value:'',
      needChoose:false,
      formItem:{
        seal_id:'',
        seal_num:'',
        apply_reason:'',
        is_sms:1
      },
      storePrams: {
        limit: 10,
        page: 1,
        total: 0,
      },
    }
  },
  created() {
    this.getSeal()
    this.getList()
  },
  methods:{
    getList(){
      applyList(this.storePrams).then(res=>{
        this.storeList = res.data.data || []
      })
    },
    creatSeal(){
      this.needChoose = true
    },
    goBack(){
      this.$emit('goBack')
    },
    agree(e){
      console.log(e)

    },
    onchangeIsShow(e){
      console.log(e)
      console.log(this.formItem)
    },
    creatIt(){
      sealCreate(this.formItem).then(res=>{
        this.$Message.success(res.msg)
        this.reset()
        this.getList()
        this.needChoose = false
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    cancelIt(){
      this.reset()
      this.needChoose = false
    },
    chooseImg(e){
      this.formItem.seal_id = e.id
    },
    getSeal(){
      sealList().then(res=>{
        this.seal_list = res.data || []
        console.log(res)
      })
    },
    clearDel(e){
      console.log(e)
    },
    reset(){
      this.formItem = {
        seal_id:'',
        seal_num:'',
        apply_reason:''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.seal-page {
  .top-change {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    height: 60px;
    .l-where {
      //position: absolute;
      //left: 30px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #1B2742;
      span {
        color: #4877E8;
      }
    }
    .r-back {
      .iconfont {
        //position: absolute;
        //right: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 26px;
        cursor: pointer;

        &:hover {
          color: #4877E8;
        }
      }
    }
  }
  .go-back {
    width: 100px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #C8CEDA;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    color: #545F78;
    cursor: pointer;
  }

  .seal-nav {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    margin-top: 20px;

    .seal-title {
      font-size: 18px;
      font-weight: bold;
      color: #545F78;
    }

    .choose-style {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .blue {
        margin-right: 20px;
      }
    }

    .seal-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .seal-nav-item {
        margin-right: 20px;
        .seal-img {
          width: 200px;
          height: 200px;
          background: #FFFFFF;
          border: 1px solid #C8CEDA;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          img{
            width: 140px;
            height: 140px;
            object-fit: cover;
          }
          &:hover .do-it{
            display: inline-block;
          }
          &:hover .do-btn{
            display: flex;
          }
          .do-it {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: #191A1F;
            opacity: 0.3;
            display: none;
          }
          .do-btn {
            display: flex;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            display: none;
            .l-icons {
              flex: 1;
              height: 40px;
              background: white;
              cursor: pointer;
              &:first-child{
                border-right: 1px solid #C8CEDA;
                .iconfont{
                  color: #4877E8;
                  font-size: 24px;
                }
              }
              &:last-child{
                .iconfont{
                  color: #FD5454;
                  font-size: 21px;
                }
              }
            }
          }
        }
        .use-btn {
          width: 70px;
          height: 34px;
          background: #FFFFFF;
          border: 1px solid #C8CEDA;
          border-radius: 4px;
          font-size: 16px;
          color: #545F78;
          cursor: pointer;
          margin-top: 10px;
          &:hover{
            border-color: #4877E8;
            color: #4877E8;
          }
        }
      }
    }
  }

  .table-wrap {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    margin-top: 20px;
    .blue {
      margin-left: unset;
      margin-top: 20px;
    }
  }
  .choose-pop {
    .modal {
      width: 600px;
      background: white;
      border-radius: 8px;
      .top-wrap {
        background: white;
        padding: 30px;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow:2px 5px 2px -2px  #e7e5e0;
      }

      .b-table {
        margin-top: 20px;
        background: white;
        padding: 30px;
        box-sizing: border-box;
      }
      .new-title {
        font-size: 18px;
        font-weight: bold;
        color: black;
      }
      .wrap-label {
        font-size: 15px;
        color: #333333;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
        &:first-child {
          margin-top: unset;
        }
      }
      .wrap-label-s {
        font-size: 15px;
        color: #333333;
        font-weight: bold;
      }
      .seal-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .seal-img {
        width: 140px;
        height: 140px;
        background: #FFFFFF;
        border: 1px solid #C8CEDA;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
        img {
          width: 110px;
          height: 110px;
          object-fit: cover;
        }
        &:hover {
          border: 1px solid #1A73E8;
        }
      }
      .seal-img.active {
        border: 1px solid #1A73E8;
      }
    }
  }
}

</style>
