<template>
  <div>
    <div class="top-change">
      <div class="l-where">
        系统设置<span>/</span>签署者管理
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="b-table">
      <div style="display: flex;margin-bottom: 20px">
        <div style="display: flex;align-items: center;margin-right: 20px">
          <Input v-model="storePrams.title" style="width: 300px" enter-button placeholder="请输入姓名、电话、企业名称进行搜索"/>
        </div>
        <div>
          <Button type="primary" @click="getList">搜索</Button>
        </div>
      </div>
      <div class="creat-table" @click="creatTalbe">创建</div>
      <Table :columns="platformColumns" :data="storeList">
        <template slot-scope="{ row }" slot="operation">
          <div class="flex-c">
            <div class="table-btn edit" @click="agree(row.id)">编辑</div>
            <div class="table-btn danger" @click="clearDel(row.id)">删除</div>
          </div>
        </template>
        <template slot-scope="{ row }" slot="type">
          <div>{{row.type == '0' ? '个人' : '企业'}}</div>
        </template>
      </Table>
      <br/>
      <Page :total="storePrams.total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
    <Modal
        :title="tableTitle"
        v-model="showMsg"
        width="600"
        @on-ok="submit"
        :mask-closable="false">
      <Form ref="formItem" :model="platform" :label-width="100" style="width: 500px;margin-top: 20px">
        <FormItem label="所属主体">
          <Select v-model="platform.type" placeholder="请选择所属主体">
            <Option value='0'>个人</Option>
            <Option value='1'>企业</Option>
          </Select>
        </FormItem>
        <FormItem label="经办人姓名">
          <Input v-model="platform.name" placeholder="请输入经办人姓名"></Input>
        </FormItem>
        <FormItem label="经办人手机号">
          <Input v-model="platform.phone" placeholder="请输入经办人手机号"></Input>
        </FormItem>
        <FormItem label="企业名称" v-if="platform.type === '1'">
          <Input v-model="platform.prise_name" placeholder="请输入企业名称"></Input>
        </FormItem>
        <FormItem label="参与方">
          <Input v-model="platform.participants" placeholder="请输入参与方"></Input>
        </FormItem>
        <FormItem label="参与方">
          <CheckboxGroup v-model="plus" @on-change="checkAllGroupChange">
            <Checkbox :label="2">经办人签字</Checkbox>
            <Checkbox :label="1" v-if="platform.type === '1'">企业公章</Checkbox>
            <Checkbox :label="4">短信验证</Checkbox>
          </CheckboxGroup>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import {createInfo, delInfo, getInfo, signatoryList, updateInfo} from '../../../api/interFace'
export default {
  name: "index",
  data() {
    return {
      platformColumns: [
        {
          title: '#',
          key: 'id',
          width: 80,
          align: 'center'
        },
        {
          title: '参与方',
          key: 'participants',
          align: 'center'
        },
        {
          title: '姓名',
          key: 'name',
          align: 'center'
        },
        {
          title: '电话',
          key: 'phone',
          align: 'center'
        },
        {
          title: '所属主体',
          slot: 'type',
          align: 'center'
        },
        {
          title: '企业名称',
          key: 'prise_name',
          align: 'center'
        },
        {
          title: '操作',
          key: 'action',
          width: 210,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      storeList: [],
      showMsg: false,
      tableTitle:'新建签署者',
      storePrams: {
        limit: 10,
        page: 1,
        total: 0,
        title:''
      },
      platform:{
        contacts_name:'',
        participants:'',
        name:'',
        phone:'',
        requirement:'',
        type:'1',
        prise_name:''
      },
      plus:[],
      refuse_reason: '',
      needId: '',
      is_sms: 1,
      leftImg:'',
      rightImg:''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    creatTalbe(){
      this.showMsg = true
      this.reset()
    },
    goBack(){
      this.$emit('goBack')
    },
    submit(){
      if(this.platform.type  !== '1'){
        this.platform.prise_name = ''
      }
      console.log(this.platform.prise_name)
      this.platform.requirement = this.sum(this.plus)
      if(this.platform.id){
        updateInfo(this.platform).then(res=>{
          this.$Message.success(res.msg)
          this.getList()
        }).catch(res=>{
          this.$Message.error(res.msg)
        })
      }else {
        createInfo(this.platform).then(res=>{
          this.$Message.success(res.msg)
          this.getList()
        }).catch(res=>{
          this.$Message.error(res.msg)
        })
      }
    },
    sum(arr) {
      var s = 0;
      for (var i = arr.length - 1; i >= 0; i--) {
        s += arr[i];
      }
      return s;
    },
    checkAllGroupChange(){
    },
    getList() {
      signatoryList(this.storePrams).then(res => {
        this.storeList = res.data.data || []
        this.storePrams.total = res.data.total
      }).catch(res => {
        console.log(res)
      })
    },
    clearDel(id) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此条签署者信息，删除后无法恢复!!!',
        onOk: () => {
          delInfo({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    agree(id){
      this.showMsg = true
      this.tableTitle='新建签署者'
      getInfo({id:id}).then(res=>{
        this.platform = res.data
        this.platform.type = String(res.data.type)
        if(res.data.requirement === 1 ) {
          this.plus = [1]
        }else if(res.data.requirement === 2 ) {
          this.plus = [2]
        }else if(res.data.requirement === 3){
          this.plus = [1, 2]
        }else if(res.data.requirement === 4 ) {
          this.plus = [4]
        }else if(res.data.requirement === 5 ) {
          this.plus = [1, 4]
        }else if(res.data.requirement === 6 ) {
          this.plus = [2, 4]
        }else if(res.data.requirement === 7 ) {
          this.plus = [1, 2, 4]
        }
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    reset(){
      this.platform = {
        contacts_name: '',
        participants: '',
        name: '',
        phone: '',
        requirement: '',
        type: '1'
      }
      this.plus = []
    }
  }
}
</script>

<style scoped lang="scss">
.top-change {
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  box-sizing: border-box;
  height: 60px;
.l-where {
  //position: absolute;
  //left: 30px;
  //top: 50%;
  //transform: translateY(-50%);
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1B2742;
span {
  color: #4877E8;
}
}
.r-back {
.iconfont {
  //position: absolute;
  //right: 30px;
  //top: 50%;
  //transform: translateY(-50%);
  font-size: 26px;
  cursor: pointer;

&:hover {
   color: #4877E8;
 }
}
}
}
.top-wrap {
  background: white;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 20px;
  border-radius: 4px;
  box-shadow: 2px 5px 2px -2px #e7e5e0;
}

.input-wrap {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.b-table {
  margin-top: 20px;
  background: white;
  padding: 30px;
  box-sizing: border-box;
}

.wrap-label {
  font-size: 14px;
  margin-right: 12px;
}
.l-pic,.r-pic {
  width: 150px;
  height: 150px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.creat-table {
  font-size: 12px;
  padding: 5px 12px;
  border: 1px solid #1a9aef;
  color: #1a9aef;
  border-radius: 6px;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 20px;
}
</style>

