<template>
  <div class="user-page">
    <div class="top-change">
      <div class="l-where">
        系统设置<span>/</span>用户管理
      </div>
      <div class="middle-width flex-b">
        <div class="nav-name" :class="storePrams.type === '1' ? 'active' : ''" @click="changeTable('1')">管理员</div>
        <div class="nav-name" :class="storePrams.type === '2' ? 'active' : ''" @click="changeTable('2')">普通用户</div>
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="table-card">
      <div class="top-search flex">
        <Input v-model="storePrams.phone" placeholder="请输入姓名、电话、进行搜索" style="width: 300px"/>
        <div class="search-btn flex-c" @click="getlist">搜索</div>
      </div>
      <div class="creat flex-c" @click="creatUser" v-if="storePrams.type === '1'">创建</div>
      <Table :columns="columnTable" :loading="loading" :data="dataTable" style="margin-top: 20px">
        <template slot-scope="{row}" slot="operation">
          <div class="flex-c">
            <div class="table-btn blue new-table-btn" @click="goMove(row)">合同转移</div>
            <div class="table-btn del new-table-btn" @click="cancelIt(row)">解除</div>
            <div class="table-btn edit new-table-btn" @click="editInfo(row)">编辑</div>
            <div class="table-btn danger new-table-btn" @click="delThis(row)">删除</div>
          </div>
        </template>
        <template slot-scope="{row}" slot="operations">
          <div class="flex-c">
            <div class="table-btn del" @click="cancelIt(row)">解除</div>
            <div class="table-btn danger" @click="delThis(row)">删除</div>
          </div>
        </template>
        <template slot-scope="{index}" slot="index">
          <div class="flex-c">
            {{index + 1}}
          </div>
        </template>
        <template slot-scope="{row}" slot="tell">
          <div class="note-style">{{ row.openid ? '已绑定' : '未绑定' }}</div>
        </template>
        <template slot-scope="{row}" slot="note">
          <div class="note-style" @click="changeNote(row)">{{ row.sms_num }}</div>
        </template>
        <template slot-scope="{row}" slot="number">
          <div class="note-style" @click="changeContract(row)">{{ row.contract_num }}</div>
        </template>
      </Table>
      <Page :total="storePrams.total"
            :current="storePrams.page"
            style="text-align:right;margin-top: 20px"
            show-elevator
            show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
    <!--  短信修改次数-->
    <div class="show-table" v-if="needTable">
      <div class="pop"></div>
      <div class="pop-table fixed">
        <div class="t-c" @click="needTable = false">
          <Icon type="md-close" size="25" />
        </div>
        <div class="nav-switch">
          <div class="label" style="margin-right: 16px">短信次数配置</div>
          <i-switch v-model="messageForm.sms_switch" :true-value="1" :false-value="0" size="large" @on-change="closeSms">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </div>
        <div v-if="messageForm.sms_switch === 1">
          <div class="flex">
            <div class="btn flex-c" @click="needMsg = true">变动短信</div>
            <div class="last" >当前剩余：{{ messageForm.sms_num }}条</div>
          </div>
          <Table :columns="columnMsg" :data="dataMsg" style="margin-top: 20px">
            <template slot-scope="{index}" slot="idx">
              <div class="flex-c">
                {{index + 1}}
              </div>
            </template>
          </Table>
          <Page :total="morePrams.total"
                :current="morePrams.page"
                style="text-align:right;margin-top: 20px"
                show-elevator
                show-total
                @on-change="pageSmsChange"
                :page-size="morePrams.limit"/>
        </div>
      </div>
    </div>
    <div class="show-change" v-if="needMsg">
      <div class="pop"></div>
      <div class="pop-note fixed">
        <div class="t-c" @click="needMsg = false">
          <Icon type="md-close" size="25" />
        </div>
        <div class="input-nav flex">
          <div class="label">短信数量</div>
          <div class="val">
            <Input v-model="messageForm.sms_num" type="number" placeholder="请输入短信数量"/>
          </div>
        </div>
        <div class="give">
          <div class="cancel flex-c" @click="needMsg = false">取 消</div>
          <div class="require flex-c" @click="resetNum">确 定</div>
        </div>
      </div>
    </div>
    <!--  合同修改次数-->
    <div class="show-table" v-if="needContractTable">
      <div class="pop"></div>
      <div class="pop-table fixed">
        <div class="t-c" @click="needContractTable = false">
          <Icon type="md-close" size="25" />
        </div>
        <div class="nav-switch">
          <div class="label" style="margin-right: 16px">合同次数配置</div>
          <i-switch v-model="messageForm.contract_switch" :true-value="1" :false-value="0" @on-change="closeContract" size="large">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch >
        </div>
        <div v-if="messageForm.contract_switch === 1">
          <div class="flex">
            <div class="btn flex-c" @click="needContractMsg = true">变动合同数量</div>
            <div class="last" >当前剩余：{{ messageForm.contract_num }}条</div>
          </div>
          <Table :columns="columnMsg" :data="dataContractMsg" style="margin-top: 20px">
            <template slot-scope="{index}" slot="idx">
              <div class="flex-c">{{index + 1}}</div>
            </template>
          </Table>
          <Page :total="morePrams.total"
                :current="morePrams.page"
                style="text-align:right;margin-top: 20px"
                show-elevator
                show-total
                @on-change="pageSmsChange"
                :page-size="morePrams.limit"/>
        </div>
      </div>
    </div>
    <div class="show-change" v-if="needContractMsg">
      <div class="pop"></div>
      <div class="pop-note fixed">
        <div class="t-c" @click="needContractMsg = false">
          <Icon type="md-close" size="25" />
        </div>
        <div class="input-nav flex">
          <div class="label">合同数量</div>
          <div class="val">
            <Input v-model="messageForm.contract_num" type="number" placeholder="请输入合同数量"/>
          </div>
        </div>
        <div class="give">
          <div class="cancel flex-c" @click="needContractMsg = false">取 消</div>
          <div class="require flex-c" @click="resetContractNum">确 定</div>
        </div>
      </div>
    </div>
    <!--  合同修改次数-->
    <div class="show-change" v-if="moveContract">
      <div class="pop"></div>
      <div class="pop-note fixed">
        <div class="t-c" @click="moveContract = false">
          <Icon type="md-close" size="25" />
        </div>
        <div class="input-nav flex">
          <div class="label">管理员</div>
          <div class="val">
            <Select v-model="transferor_uid">
              <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.real_name }}</Option>
            </Select>
          </div>
        </div>
        <div class="give">
          <div class="cancel flex-c" @click="moveContract = false">取 消</div>
          <div class="require flex-c" @click="resetElseNum">确 定</div>
        </div>
      </div>
    </div>
    <!-- 创建用户弹出层-->
    <modal v-if="showModal" :modalInfo="modalInfo" @giveVal="getVal" @closeModal="showModal=false"></modal>
  </div>
</template>

<script>
import modal from '@/components/modal/index'
import {
  addadministrator,
  permlist,
  getCsList,
  getConfInfo,
  setCsnum,
  uppassword,
  managerDelete, relieve, getUserList, contractTransfer
} from '../../../api/interFace'
export default {
  name: "user",
  components:{
    modal
  },
  data(){
    return{
      activeType:'1',
      activeNav:'1',
      showModal:false,
      needTable:false,
      moveContract:false,
      needContractTable:false,
      needMsg:false,
      needContractMsg:false,
      contract_name:'',
      modalInfo: {
        title: '创建管理员',
        inputList: [
          {
            label: '姓名',
            inputType: 'text',
            inputVal: ''
          },
          {
            label: '手机号',
            inputType: 'number',
            inputVal: ''
          },
          {
            label: '密码',
            inputType: 'password',
            inputVal: ''
          }
        ]
      },
      columnTable: [
        {
          title: '序号',
          align: 'center',
          width: 120,
          slot: 'index'
        },
        {
          title: '姓名',
          align: 'center',
          key: 'real_name'
        },
        {
          title: '手机号',
          align: 'center',
          key: 'phone'
        },
        {
          title: '是否绑定',
          align: 'center',
          slot: 'tell'
        },
        {
          title: '短信次数',
          align: 'center',
          slot: 'note'
        },
        {
          title: '合同次数',
          align: 'center',
          slot: 'number'
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 320,
          slot: 'operation'
        }
      ],
      dataTable: [],
      storePrams: {
        limit: 10,
        page: 1,
        total:0,
        phone: '',
        type:'1'
      },
      morePrams: {
        limit: 5,
        page: 1,
        total:0,
        uid: '',
        type:''
      },
      columnMsg:[
        {
          title: '#',
          align: 'center',
          slot: 'idx'
        },
        {
          title: '变动次数',
          align: 'center',
          key: 'num'
        },
        {
          title: '变动时间',
          align: 'center',
          key: 'created_at'
        }
      ],
      dataMsg:[],
      cityList:[],
      dataContractMsg:[],
      cardMsg:'',
      administrator: {
        real_name: '',
        phone: '',
        password: '',
        role_id: '3',
      },
      loading: false,
      needType:2,
      messageForm: {
        contract_num: '0',
        contract_switch: 0,
        sms_switch: 0,
        sms_num: '0',
        uid: ''
      },
      editId:'',
      transferor_uid:''
    }
  },
  created() {
    this.getlist()
  },
  methods:{
    goBack(){
      this.$emit('goBack')
    },
    goMove(row){
      this.messageForm.uid = row.id
      this.moveContract = true
      this.getUser()
    },
    resetElseNum(){
      contractTransfer({transferor_uid:this.transferor_uid,uid:this.messageForm.uid}).then(res=>{
        this.$Message.success(res.msg)
        this.moveContract = false
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    closeSms(e){
      if(e === 0) {
        this.messageForm.sms_switch = 0
        setCsnum(this.messageForm).then(res=>{
          this.$Message.success(res.msg)
          this.getlist()
          this.needMsg = false
          this.needTable = false
          this.resetMessageForm()
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }
    },
    closeContract(e){
      if(e === 0) {
        this.messageForm.contract_switch = 0
        setCsnum(this.messageForm).then(res=>{
          this.$Message.success(res.msg)
          this.getlist()
          this.needContractMsg = false
          this.needContractTable = false
          this.resetMessageForm()
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }
    },
    getUser() {
      getUserList({uid:this.messageForm.uid}).then(res=>{
        this.cityList = res.data
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    cancelIt(row) {
      this.$Modal.confirm({
        title: '解绑提示',
        content: '请问您是否确认解绑此条用户！！！',
        onOk: () => {
          relieve({id:row.id}).then(res=>{
            this.$Message.success(res.msg)
            this.getlist()
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    delThis(row){
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此条用户信息，删除后无法恢复!!!',
        onOk: () => {
          managerDelete({id:row.id}).then(res=>{
            this.$Message.success(res.msg)
            this.getlist()
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    creatUser() {
      this.modalInfo = {
        title: '创建管理员',
        inputList: [
          {
            label: '姓名',
            inputType: 'text',
            inputVal: ''
          },
          {
            label: '手机号',
            inputType: 'number',
            inputVal: ''
          },
          {
            label: '密码',
            inputType: 'password',
            inputVal: ''
          }
        ]
      }
      this.showModal = true
    },
    editInfo(row){
      this.modalInfo = {
        title: '编辑管理员',
        inputList: [
          {
            label: '姓名',
            inputType: 'text',
            inputVal: ''
          },
          {
            label: '新密码',
            inputType: 'password',
            inputVal: ''
          },
          {
            label: '重复密码',
            inputType: 'password',
            inputVal: ''
          }
        ]
      }
      this.showModal = true
      this.editId = row.id
      this.modalInfo.inputList[0].inputVal = row.real_name
      // this.modalInfo.inputList[1].inputVal = row.phone
    },
    getSideTable(){
      this.morePrams.uid = this.messageForm.uid
      this.morePrams.type = this.needType
      getCsList(this.morePrams).then(res=>{
        if(this.needType === 2) {
          this.dataMsg = res.data.data
          this.morePrams.total = res.data.total
        }else  {
          this.dataContractMsg = res.data.data
          this.morePrams.total = res.data.total
        }
      })
    },
    pageSmsChange(index){
      this.morePrams.page = index
      this.getSideTable()
    },
    changeNote(row){
      this.needType = 2
      this.messageForm.type = 2
      this.messageForm.uid = row.id
      this.needTable = true
      this.modifyMsg()
      this.getSideTable()
    },
    changeContract(row){
      this.needType = 1
      this.messageForm.type = 1
      this.messageForm.uid = row.id
      this.needContractTable = true
      this.modifyMsg()
      this.getSideTable()
    },
    modifyMsg(){
      getConfInfo({uid:this.messageForm.uid}).then(res=>{
        this.messageForm.contract_num = res.data.contract_num
        this.messageForm.contract_switch = Number(res.data.contract_switch)
        this.messageForm.sms_switch = Number(res.data.sms_switch)
        this.messageForm.sms_num = res.data.sms_num
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    resetContractNum(){
      setCsnum(this.messageForm).then(res=>{
        this.$Message.success(res.msg)
        this.getlist()
        this.needContractMsg = false
        this.needContractTable = false
        this.resetMessageForm()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    resetNum(){
      setCsnum(this.messageForm).then(res=>{
        this.$Message.success(res.msg)
        this.getlist()
        this.needMsg = false
        this.needTable = false
        this.resetMessageForm()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    resetMessageForm() {
      this.messageForm = {
        contract_num: '0',
        contract_switch: 0,
        sms_switch: 0,
        sms_num: '0',
        uid: '',
        type:''
      }
    },
    changeTable(type){
      this.storePrams.type = type
      this.getlist()
      if(type === '2') {
        this.columnTable = [
          {
            title: '序号',
            align: 'center',
            width: 120,
            slot: 'index'
          },
          {
            title: '姓名',
            align: 'center',
            key: 'real_name'
          },
          {
            title: '手机号',
            align: 'center',
            key: 'phone'
          },
          {
            title: '是否绑定',
            align: 'center',
            slot: 'tell'
          },
          {
            title: '操作',
            align: 'center',
            fixed: 'right',
            width: 180,
            slot: 'operations'
          }
        ]
      }else {
        this.columnTable = [
          {
            title: '序号',
            align: 'center',
            width: 120,
            slot: 'index'
          },
          {
            title: '姓名',
            align: 'center',
            key: 'real_name'
          },
          {
            title: '手机号',
            align: 'center',
            key: 'phone'
          },
          {
            title: '是否绑定',
            align: 'center',
            slot: 'tell'
          },
          {
            title: '短信次数',
            align: 'center',
            slot: 'note'
          },
          {
            title: '合同次数',
            align: 'center',
            slot: 'number'
          },
          {
            title: '操作',
            align: 'center',
            fixed: 'right',
            width: 320,
            slot: 'operation'
          }
        ]
      }
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getlist()
    },
    getlist(){
      this.loading = true
      permlist(this.storePrams).then(async res => {
        this.dataTable = res.data.data || []
        this.storePrams.total = res.data.total
        this.loading = false
      }).catch(res => {
        this.loading = false
        this.$Message.error(res.msg)
      })
    },
    getVal(e) {
      if(this.editId) {
        this.administrator.real_name = e[0]
        this.administrator.new_password = e[1]
        this.administrator.true_password = e[2]
        this.administrator.id = this.editId
        uppassword(this.administrator).then(res => {
          this.$Message.success(res.msg)
          this.getlist()
          this.reset()
          this.showModal = false
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }else {
        this.administrator.real_name = e[0]
        this.administrator.phone = e[1]
        this.administrator.password = e[2]
        addadministrator(this.administrator).then(res => {
          this.$Message.success(res.msg)
          this.getlist()
          this.reset()
          this.showModal = false
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }
    },
    reset(){
      this.administrator.role_id = ''
      this.administrator.password = ''
      this.administrator.real_name = ''
      this.administrator.phone = ''
    }
  }
}
</script>

<style scoped lang="scss">
.user-page {
  .top-change {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    .l-where {
      //position: absolute;
      //left: 30px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 18px;
      font-weight: bold;
      color: #1B2742;
      span {
        color: #4877E8;
      }
    }
    .r-back {
      .iconfont {
        //position: absolute;
        //right: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 26px;
        cursor: pointer;
        &:hover {
          color: #4877E8;
        }
      }
    }
    .middle-width {
      width: 18%;
      margin: auto;
    }
    .nav-name {
      font-size: 20px;
      font-weight: bold;
      color: #545F78;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
      position:relative;
      &:hover{
        color: #4877E8;
      }
    }
    .active {
      color: #4877E8;
      &:after{
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #4877E8;
        border-radius: 1px;
      }
    }
  }
  .new-table-btn{
    font-size:12px
  }
  .table-card {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin-top: 20px;
    .top-search {
      .search-btn {
        width: 78px;
        height: 30px;
        background: #4877E8;
        border-radius: 15px;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        margin-left: 20px;
        cursor: pointer;
        &:hover{
        opacity: .8;
      }
      }
    }
    .creat {
      width: 64px;
      height: 28px;
      background: #FFFFFF;
      border: 1px solid #4877E8;
      border-radius: 6px;
      font-size: 14px;
      font-weight: bold;
      color: #4877E8;
      margin-top: 20px;
      //margin-bottom: 20px;
      cursor: pointer;
      &:hover{
        background: #4877E8;
        color: white;
      }
    }
    .note-style {
      height: 30px;
      line-height: 30px;
      background: #FFFFFF;
      border: 1px solid #C8CEDA;
      border-radius: 4px;
      width: fit-content;
      padding: 0 8px;
      display: inline-block;
      font-size: 16px;
      color: #545F78;
      cursor: pointer;
    }
  }
  .show-table {
    .pop-table {
      width: 800px;
      background: white;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;
      .s-b {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }
      .t-c {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
      .btn {
        width: 106px;
        height: 34px;
        background: #FFFFFF;
        border: 1px solid #DBDEE6;
        border-radius: 6px;
        font-size: 14px;
        color: #191A1F;
        cursor: pointer;
        &:hover {
          border-color: #4877E8;
          color: #4877E8;
        }
      }
      .last {
        display: inline-block;
        font-size: 16px;
        color: #BDC4D2;
        margin-left: 20px;
      }
      .nav-switch {
        display: flex;
        margin-bottom: 20px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .show-change {
    .pop {
      z-index: 9998;
    }
    .pop-note {
      z-index: 9999;
      width: 500px;
      background: white;
      border-radius: 8px;
      padding: 80px 24px 40px 24px;
      box-sizing: border-box;
      .t-c {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
      .give {
        display: flex;
        justify-content: right;
        .require {
          width: 100px;
          height: 34px;
          background: #4877E8;
          border: 1px solid #4877E8;
          border-radius: 6px;
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
        .cancel {
          width: 100px;
          height: 34px;
          color: #1B2742;
          border-radius: 6px;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          margin-right: 20px;
          &:hover {
            color: #4877E8;
          }
        }
      }
      .input-nav {
        margin-bottom: 25px;
        .label {
          font-size: 14px;
          font-weight: bold;
          color: #545F78;
          margin-right: 20px;
        }
        .val {
          flex: 1;
        }
      }
    }
  }
}

</style>
