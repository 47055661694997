<template>
  <div class="user-page">
    <div class="top-change">
      <div class="l-where">
        系统设置<span>/</span>H5支付
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="card-bg">
      <div class="input-nav flex">
        <div class="label">公众号appid：</div>
        <div class="val">
          <Input v-model="h5Paylist.appid"  placeholder="请输入公众号appid"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">微信支付商户号：</div>
        <div class="val">
          <Input v-model="h5Paylist.mchid"  placeholder="请输入微信支付商户号"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">微信支付平台证书序列号：</div>
        <div class="val">
          <Input v-model="h5Paylist.serial_no"  placeholder="请输入微信支付平台证书序列号"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">微信支付V3密钥：</div>
        <div class="val">
          <Input v-model="h5Paylist.key"  placeholder="请输入微信支付V3密钥"/>
        </div>
      </div>
      <div class="input-nav flex">
        <div class="label">微信支付私钥证书(key)：</div>
        <div class="val">
          <div class="else-up">
            <div class="pic-wrap">
              <div class="img-list" v-if="h5Paylist.key_cert">
                <img src="../../../assets/imgs/22.png"/>
                <div class="close" @click="h5Paylist.key_cert = ''"><Icon type="ios-close-circle-outline" size="30" /></div>
              </div>
              <div v-else>
                <Upload
                    :show-upload-list="false"
                    :headers='headers'
                    :action="imageAction"
                    :on-format-error="handleFormatError"
                    :format="['pem']"
                    :on-success="uploadImgSuccess">
                  <div class="upload-style">
                    <Icon type="ios-add" size="60"/>
                    <div class="up-text">上传证书</div>
                  </div>
                </Upload>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="s-b">
        <Button type="primary" @click="subBtn">确定</Button>
      </div>
    </div>
  </div>
</template>

<script>
import {getCookies} from '@/utils/helper'
import config from '@/config/index'
import {h5pay , saveh5} from "../../../api/interFace";
export default {
  name: "user",
  components:{
  },
  data(){
    return{
      inputVal:'',
      accessoriesList:'',
      imageAction : config.apiUrl + '/adminapi/common/uploads',
      headers:{'Authori-zation' : getCookies('token')},
      h5Paylist:{
        key:"",
        appid:"",
        mchid:"",
        serial_no:"",
        key_cert:""
      },
    }
  },
  created() {
    this.getPay()
  },
  methods:{
    goBack(){
      this.$emit('goBack')
    },
    getPay(){
      h5pay().then(res=>{
        this.h5Paylist = {
          key: res.data.key,
          appid: res.data.appid,
          mchid: res.data.mchid,
          serial_no: res.data.serial_no,
          key_cert: res.data.key_cert
        }
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    subBtn(){
      if(this.h5Paylist.appid=='') {
        this.$Message.warning('请填写公众号appid');return;
      }
      if(this.h5Paylist.mchid=='') {
        this.$Message.warning('商户号不能为空');return;
      }
      if(this.h5Paylist.serial_no=='') {
        this.$Message.warning('微信支付平台证书序列号不能为空');return;
      }
      if(this.h5Paylist.key=='') {
        this.$Message.warning('微信支付V3密钥不能为空');return;
      }
      if(this.h5Paylist.key_cert=='') {
        this.$Message.warning('请上传微信支付私钥证书');return;
      }
      saveh5(this.h5Paylist).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    changeTable(type){
      this.activeNav = type
    },
    // 文件类型上传失败
    handleFormatError() {
      this.$Message.warning('暂不支持上传此类型文件')
    },
    // 判断类型上传文件
    uploadImgSuccess(e) {
      this.h5Paylist.key_cert = e.data.src
    },
  }
}
</script>

<style scoped lang="scss">
.user-page {
  .top-change {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    height: 70px;
    .l-where {
      //position: absolute;
      //left: 30px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 18px;
      font-weight: bold;
      color: #1B2742;
      span {
        color: #4877E8;
      }
    }
    .r-back {
      .iconfont {
        //position: absolute;
        //right: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 26px;
        cursor: pointer;

        &:hover {
          color: #4877E8;
        }
      }
    }
    .middle-width {
      width: 30%;
      margin: auto;
    }
    .nav-name {
      font-size: 20px;
      font-weight: bold;
      color: #545F78;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
      position:relative;
      &:hover{
        color: #4877E8;
      }
    }
    .active {
      color: #4877E8;
      &:after{
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #4877E8;
        border-radius: 1px;
      }
    }
  }
  .card-bg {
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    background: #fff;
    padding: 50px 60px;
    box-sizing: border-box;
    min-height: 450px;
    position: relative;
    .s-b {
      position: absolute;
      left: 40%;
      transform: translateX(-50%);
      bottom: 20px;
    }
    .input-nav {
      margin-bottom: 25px;
      .label {
        font-size: 14px;
        font-weight: bold;
        color: #545F78;
        text-align: right;
        width: 180px;
      }
      .long {
        width: 130px;
      }
      .val {
        width: 350px;
        .up-text {
          font-size: 14px;
          color: #545F78;
        }
        .else-up {
          display: flex;
          .upload-style {
            width: 100px;
            height: 100px;
            border-radius: 4px;
            border: 1px solid #C8CEDA;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
          }
          .pic-wrap {
            display: flex;
            flex-wrap: wrap;
            margin-left: 12px;
          }
          .img-list {
            width: 100px;
            height: 100px;
            position: relative;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 4px;
            border: 1px solid #C8CEDA;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 70px;
              height: 70px;
              object-fit: cover;
              border-radius: 4px;
            }
            .close {
              position: absolute;
              top: -15px;
              right: -15px;
              cursor: pointer;
              color: #dd0000;
              display: none;
            }
          }
          .img-list:hover .close {
            display: inline-block;
          }
        }
      }
    }
  }
  .top-number {
    width: 100%;
    padding: 40px 60px 20px 30px;
    box-sizing: border-box;
    background: white;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    .sss {
      display: flex;
      align-items: center;
      margin-left: 30px;
      &:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 60px;
        background: #DBDEE6;
        margin-left: 20px;
      }
    }
    .wrap-item {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .num {
        font-size: 44px;
        font-weight: 400;
        color: #1B2742;
      }
      .word {
        font-size: 18px;
        font-weight: bold;
        color: #818899;
      }
      .btn-s {
        width: 80px;
        height: 30px;
        margin-top: 10px;
      }
      .btn {
        width: 80px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #4877E8;
        border-radius: 6px;
        font-size: 14px;
        color: #4877E8;
        margin-top: 10px;
      }
    }

  }
  .history {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    width: 100%;
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #1B2742;
      display: flex;
      align-items: center;
      .iconfont {
        color: #4877E8;
        margin-right: 8px;
        font-size: 20px;
      }
    }
    .card-wrap {
      width: 100%;
      height: 174px;
      background: #FFFFFF;
      border: 1px solid #E4EAF9;
      border-radius: 7px;
      margin-top: 10px;
    }

  }

}

</style>
